import { QueryFunction } from "@tanstack/react-query";
import MemberService from "../services/member";
import WebSetting from "../services/webSetting";
import AnnouncementService, {
  AnnouncementFilter,
} from "../services/announcement";
import StatementService, { StatementFilter } from "../services/statement";
import FeedService from "../services/feed";
import BetTicketService from "../services/betTicket";
import { Member } from "../types/member";
import { NumberOfMatchType, Feed, TopeventFeed } from "../types/feed";
import { CricketFeed } from "../types/cricketFeed";
import {
  BetTicketInfoPayload,
  BetTicketInfoForDisplay,
  GetBetTicketPayload,
  GetReportTicketPayload,
} from "../types/betTicket";
import { BetType } from "../types/common";
import { Sport } from "../types/sport";
import { CoverBanner } from "../types/webSetting";
export type Value<T = unknown> = {
  key: (string | Record<string, string | string[] | number | null>)[];
  queryFn: QueryFunction<T>;
};

export type Key = "login" | "profile";

export type Query = {
  login: (uuid: string) => Value<string>;
  profile: () => Value<Member>;
  announcement: (filter: AnnouncementFilter) => Value<any>;
  numberOfMatchType: () => Value<NumberOfMatchType>;
  footballFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  basketballFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  volleyballFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  tabletennisFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  handballFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  icehockeyFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  tennisFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  snookerFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  baseballFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  rugbyFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  americanfootballFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  badmintonFeed: (
    type: "today" | "live" | "early",
    betType: BetType
  ) => Value<Feed[]>;
  cricketFeed: (type: "today" | "live" | "early") => Value<CricketFeed[]>;
  footballTopeventFeed: () => Value<TopeventFeed[]>;
  specialFootballFeed: (matchId: number) => Value<any[]>;
  betTicketInfo: (payload: BetTicketInfoPayload) => Value<any>;
  getBetTickets: (payload: GetBetTicketPayload) => Value<any>;
  getReportTickets: (payload: GetReportTicketPayload) => Value<any>;
  todayResult: (sportType: Sport) => Value<any>;
  lastResult: (sportType: Sport) => Value<any>;
  statement: (filter: StatementFilter) => Value<any>;
  statementDetail: (
    filter: StatementFilter & { page: number; limit: number }
  ) => Value<any>;
  // client state ==> start
  betTicketInfoPayload: () => Value<
    (BetTicketInfoPayload & BetTicketInfoForDisplay)[]
  >;
  currentTabIndex: () => Value<number>;
  oddsType: () => Value<number>;
  betType: () => Value<BetType>;
  isBetSlipToggle: () => Value<boolean>;
  isMenuFooterToggle: () => Value<boolean>;
  isLanguageToggle: () => Value<boolean>;
  isOddsTypeToggle: () => Value<boolean>;
  getCoverBanner: () => Value<CoverBanner>;
  isForceScreen: () => Value<boolean>;
  // client state ==> end
};

const member = new MemberService();
const announcement = new AnnouncementService();
const feed = new FeedService();
const betTicket = new BetTicketService();
const statement = new StatementService();
const webSetting = new WebSetting();

const query: Query = {
  login: (uuid) => ({
    key: ["login", uuid],
    queryFn: () => {
      return member.login(uuid);
    },
  }),

  profile: () => ({
    key: ["profile"],
    queryFn: member.getProfile,
  }),

  announcement: (filter) => ({
    key: ["announcement", filter],
    queryFn: () => {
      return announcement.get(filter);
    },
  }),

  numberOfMatchType: () => ({
    key: ["numberOfMatchType"],
    queryFn: feed.getNumberOfMatchType,
  }),

  footballFeed: (type, betType) => ({
    key: ["foodball", type, betType],
    queryFn: () => {
      return feed.getFootball(type, betType);
    },
  }),

  todayResult: (sportType) => ({
    key: ["todayResult", sportType],
    queryFn: () => {
      const isToday = true;
      return feed.getResult(sportType, isToday);
    },
  }),

  lastResult: (sportType) => ({
    key: ["lastResult", sportType],
    queryFn: () => {
      const isToday = false;
      return feed.getResult(sportType, isToday);
    },
  }),

  specialFootballFeed: (matchId) => ({
    key: ["specialFootballFeed", `${matchId}`],
    queryFn: () => {
      return feed.getSpecialFootball(matchId);
    },
  }),

  basketballFeed: (type, betType) => ({
    key: ["basketball", type, betType],
    queryFn: () => {
      return feed.getBasketball(type, betType);
    },
  }),

  volleyballFeed: (type, betType) => ({
    key: ["volleyball", type, betType],
    queryFn: () => {
      return feed.getVolleyball(type, betType);
    },
  }),

  cricketFeed: (type) => ({
    key: ["cricket", type],
    queryFn: () => {
      return feed.getCricket(type);
    },
  }),

  tabletennisFeed: (type) => ({
    key: ["tabletennis", type],
    queryFn: () => {
      return feed.getTabletennis(type);
    },
  }),

  handballFeed: (type) => ({
    key: ["handball", type],
    queryFn: () => {
      return feed.getHandball(type);
    },
  }),

  icehockeyFeed: (type) => ({
    key: ["icehockey", type],
    queryFn: () => {
      return feed.getIcehockey(type);
    },
  }),

  tennisFeed: (type) => ({
    key: ["tennis", type],
    queryFn: () => {
      return feed.getTennis(type);
    },
  }),

  snookerFeed: (type) => ({
    key: ["snooker", type],
    queryFn: () => {
      return feed.getSnooker(type);
    },
  }),

  baseballFeed: (type) => ({
    key: ["baseball", type],
    queryFn: () => {
      return feed.getBaseball(type);
    },
  }),

  rugbyFeed: (type) => ({
    key: ["rugby", type],
    queryFn: () => {
      return feed.getRugby(type);
    },
  }),

  americanfootballFeed: (type) => ({
    key: ["americanfootball", type],
    queryFn: () => {
      return feed.getAmericanfootball(type);
    },
  }),

  badmintonFeed: (type) => ({
    key: ["badminton", type],
    queryFn: () => {
      return feed.getBadminton(type);
    },
  }),

  footballTopeventFeed: () => ({
    key: ["footballTopevent"],
    queryFn: () => {
      return feed.getTopeventFoottabll();
    },
  }),

  betTicketInfo: (payload) => ({
    key: ["betTicketInfo", payload],
    queryFn: () => {
      return betTicket.getBetTicketInfo(payload);
    },
  }),

  getBetTickets: (payload) => ({
    key: ["getBetTickets", payload],
    queryFn: () => {
      return betTicket.getBetTickets(payload);
    },
  }),

  getReportTickets: (payload) => ({
    key: ["getReportTickets", payload],
    queryFn: () => {
      return betTicket.getReportTickets(payload);
    },
  }),

  statement: (filter) => ({
    key: ["statement", filter],
    queryFn: () => {
      return statement.get(filter);
    },
  }),

  statementDetail: (filter) => ({
    key: ["statementDetail", filter],
    queryFn: () => {
      return statement.getDetail(filter);
    },
  }),

  betTicketInfoPayload: () => ({
    key: ["betTicketInfoPayload"],
    queryFn: async () => {
      return [];
    },
  }),

  currentTabIndex: () => ({
    key: ["currentTabIndex"],
    queryFn: async () => {
      return 0;
    },
  }),

  oddsType: () => ({
    key: ["oddsType"],
    queryFn: async () => {
      return 0;
    },
  }),

  betType: () => ({
    key: ["betType"],
    queryFn: async () => {
      return "normal" as BetType;
    },
  }),

  isBetSlipToggle: () => ({
    key: ["isBetSlipToggle"],
    queryFn: () => {
      return false;
    },
  }),

  isMenuFooterToggle: () => ({
    key: ["isMenuFooterToggle"],
    queryFn: () => {
      return false;
    },
  }),

  isLanguageToggle: () => ({
    key: ["isLanguageToggle"],
    queryFn: () => {
      return false;
    },
  }),

  isOddsTypeToggle: () => ({
    key: ["isOddsTypeToggle"],
    queryFn: () => {
      return false;
    },
  }),

  getCoverBanner: () => ({
    key: ["getCoverBanner"],
    queryFn: () => {
      return webSetting.getCoverBanner();
    },
  }),

  isForceScreen: () => ({
    key: ["isForceScreen"],
    queryFn: () => {
      return false;
    },
  }),
};

export default query;
