import styled from "styled-components";

export const StyledTable = styled.div.attrs({
  className: "overflow-auto",
})<{ isLive?: boolean }>`
  & table {
    border-collapse: separate;
    border-spacing: 0px;
  }
  & thead > tr:first-child {
    th:first-child {
      border-radius: 10px 0 0 0;
    }
    th:last-child {
      border-radius: 0 10px 0 0;
    }
  }
  & svg.loading {
    color: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.HeaderBackground
        : theme.sportNonLive.HeaderBackground};
  }
  & tr.even-row {
    background-color: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.TableHardBackground
        : theme.sportNonLive.TableHardBackground};
  }

  & tr.odd-row {
    background-color: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.TableBackground
        : theme.sportNonLive.TableBackground};
  }

  & th {
    background-color: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.HeaderBackground
        : theme.sportNonLive.HeaderBackground};
    color: white; // TODO: we will add this one to theme object later
    font-size: 0.8rem;
    border: 1px solid
      ${({ theme, isLive }) =>
        isLive ? theme.sportLive.Border : theme.sportNonLive.Border};
    /* padding: 8px; */
  }

  & th.league {
    background-color: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.TitleLeagueBackground
        : theme.sportNonLive.TitleLeagueBackground};
  }

  & th.special {
    background-color: ${({ theme, isLive }) =>
      isLive
        ? theme.sportLive.TableBackground
        : theme.sportNonLive.TableBackground};

    color: #000;
  }

  & td {
    border: 1px solid
      ${({ theme, isLive }) =>
        isLive ? theme.sportLive.Border : theme.sportNonLive.Border};
    /* padding: 8px; */
    font-size: 0.8rem;
  }

  // เวลา

  & thead > tr:nth-child(1) > th:nth-child(1),
  & td:nth-child(1) {
    width: 100px;
  }
  & td:nth-child(1) {
    text-align: center;
    color: ${({ theme, isLive }) =>
      isLive ? theme.global.textColorGoodTeamAndPrice : "initial"};
    font-weight: bold;
  }

  // การแข่งขัน
  & td:nth-child(2),
  & thead > tr:nth-child(1) > th:nth-child(2) {
    width: 240px;
  }

  // เต็มเวลา-HDP
  & td:nth-child(3) {
    min-width: 85px;
  }

  // เต็มเวลา-OU
  & td:nth-child(4) {
    min-width: 85px;
  }

  // เต็มเวลา-1x2
  & td:nth-child(5) {
    min-width: 60px;
  }

  // เต็มเวลา-คู่คี่
  & td:nth-child(6) {
    min-width: 70px;
  }

  // ครึ่งแรก-HDP
  & td:nth-child(7) {
    min-width: 85px;
  }

  // ครึ่งแรก-OU
  /* & td:nth-child(8) {
    min-width: 85px;
  } */

  // ครึ่งแรก-1x2
  & td:nth-child(9) {
    min-width: 60px;
  }
`;

export const StyledButton = styled.span`
  & button {
    border: 1px solid #3080ed;
    color: #3080ed;
    border-radius: 0.25rem;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
  }
`;

export const StyledButtonMobile = styled.span`
  & button {
    background-color: ${({ theme }) => theme.global.Primary};
    color: ${({ theme }) => theme.global.textColorInPrimary};
    border-radius: 0.25rem;
    width: 34px;
    height: 34px;
    min-height: 34px;
  }
`;
